<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle has-text-dark">
              {{ modalTitle }}
            </p>
          </div>
          <div class="column is-narrow">
            <a
              class="has-text-danger"
              v-if="!permissions.static"
              @click="close"
            >Cancel</a>
          </div>
        </div>
        <div class="content has-text-dark">
          <p>
            {{ data.instructionsText || defaultInstructionsText }}
          </p>
        </div>
        <VehicleSummaryBox v-bind="{ description: data.description, vin: request.vin }" />
        <MileageEdit
          :mileage="+request.mileage"
          :mileage-estimated="request.mileageEstimated"
          @updateMileage="updateMileage"
          @track="track"
        />
        <section
          v-if="permissions.mileageData && $experian"
          class="box"
        >
          <div class="columns is-multiline filter-menu-dropdown">
            <div class="column radio-tick">
              <checkbox
                v-model="request.mileageData"
                name="values"
              >
                <span>Request mileage data</span>
              </checkbox>
            </div>
          </div>
        </section>
        <section
          v-if="data.serviceType === 'AutoCheck'"
          class="columns"
        >
          <div class="column">
            <p class="is-size-5 has-text-info">
              Valuation options
            </p>
          </div>
          <div
            v-if="!request.mileage"
            class="column is-narrow"
          >
            <p>
              <span class="icon is-valigned has-text-warning">
                <i class="fal fa-info-circle" />
              </span>
              <span
                class="is-valigned has-text-grey"
              >Valuations are not available without a mileage</span>
            </p>
          </div>
        </section>
        <section
          v-if="data.serviceType === 'AutoCheck' && request.mileage"
        >
          <div class="columns is-multiline filter-menu-dropdown">
            <div
              v-if="hasCazanaValues"
              class="column is-12 radio-tick"
            >
              <checkbox
                v-model="request.valuationCazana"
                name="values"
              >
                <div class="columns is-vcentered">
                  <div class="column is-narrow">
                    <span class="is-valigned">Valuation</span>
                  </div>
                  <div
                    v-if="isOnTrial"
                    class="column has-text-right"
                  >
                    <span class="has-text-soft has-text-weight-semibold">
                      Percayso Vehicle Intelligence valuations are free while
                      you are on trial!
                    </span>
                  </div>
                </div>
              </checkbox>
            </div>
            <div
              v-else
              class="column is-12"
            >
              <div class="columns is-mobile">
                <div class="column is-narrow">
                  <span class="icon is-valigned has-text-danger">
                    <i class="far fa-times" />
                  </span>
                  <span class="ml-1 is-valigned has-text-grey-light">
                    Valuation
                  </span>
                </div>
                <div class="column has-text-right">
                  <button
                    @click="toggleUpsell"
                    class="button is-small is-rounded is-soft"
                  >
                    Enable
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          class="columns"
          v-if="$experian && permissions.dataGuarantee"
        >
          <div class="column">
            <p class="is-size-5 has-text-info">
              Data guarantee
            </p>
          </div>
        </div>
        <section
          class="box"
          v-if="$experian && permissions.dataGuarantee"
        >
          <div class="columns is-multiline filter-menu-dropdown">
            <div class="column">
              <p>
                Term of cover
                <span class="has-text-grey-light">(months)</span>
              </p>
            </div>
            <div class="column is-narrow">
              <div class="buttons">
                <button
                  :class="{ 'is-success': request.dgTerm === 12 }"
                  class="button"
                  @click="request.dgTerm = 12"
                >
                  12
                </button>
                <button
                  :class="{ 'is-success': request.dgTerm === 24 }"
                  class="button"
                  @click="request.dgTerm = 24"
                >
                  24
                </button>
                <button
                  :class="{ 'is-success': request.dgTerm === 36 }"
                  class="button"
                  @click="request.dgTerm = 36"
                >
                  36
                </button>
              </div>
            </div>
          </div>
          <div class="columns is-multiline filter-menu-dropdown">
            <div class="column">
              <p>
                Level of cover
                <span class="has-text-grey-light">(thousand £)</span>
              </p>
            </div>
            <div class="column is-narrow">
              <div class="buttons">
                <button
                  :class="{ 'is-success': request.dgLevel === 10000 }"
                  class="button"
                  @click="request.dgLevel = 10000"
                >
                  10
                </button>
                <button
                  :class="{ 'is-success': request.dgLevel === 20000 }"
                  class="button"
                  @click="request.dgLevel = 20000"
                >
                  20
                </button>
                <button
                  :class="{ 'is-success': request.dgLevel === 30000 }"
                  class="button"
                  @click="request.dgLevel = 30000"
                >
                  30
                </button>
                <button
                  :class="{ 'is-success': request.dgLevel === 40000 }"
                  class="button"
                  @click="request.dgLevel = 40000"
                >
                  40
                </button>
                <button
                  :class="{ 'is-success': request.dgLevel === 50000 }"
                  class="button"
                  @click="request.dgLevel = 50000"
                >
                  50
                </button>
              </div>
            </div>
            <div class="column is-12">
              <p>
                The data guarantee is subject to the
                <a
                  href="https://www.experian.co.uk/assets/automotive/data-guarantee-terms-and-conditions.pdf"
                  target="_blank"
                >Terms and conditions</a>.
              </p>
            </div>
          </div>
        </section>
        <div
          v-if="step === 1"
          class="buttons is-right mt-5"
        >
          <button
            :class="{ 'is-loading': $wait.is('loading') }"
            :disabled="$wait.is('loading')"
            class="button is-info is-rounded"
            @click="submit"
          >
            {{ data.submitButtonText || defaultSubmitButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import * as provenance from '../services'
import * as motorcheck from 'modules/motorcheck/services'
import * as autocheck from 'modules/autocheck/services'

export default {
  name: 'Precheck',
  components: {
    MileageEdit: () => import('../components/mileageEdit'),
    VehicleSummaryBox: () =>
      import('modules/shared/vehicle-summary-box/VehicleSummaryBox')
  },
  data: () => ({
    step: 1,
    editMileage: false,
    customMileage: null,
    defaultInstructionsText: "Let's customise your check. Choose the options you'd like including.",
    defaultSubmitButtonText: 'Run Check',
    request: {
      vrm: '',
      vin: '',
      mileageData: false,
      valuationCazana: true,
      valuationCap: false,
      valuationGlass: false,
      dgLevel: null,
      dgTerm: null,
      mileage: null,
      mileageEstimated: false
    }
  }),
  computed: {
    ...mapState({
      data: state => state.modal.config
    }),
    ...mapGetters('auth', [
      'permissions',
      'user',
      'isOnTrial',
      'hasCazanaValues'
    ]),
    modalTitle() {
      if (this.data.customTitle) {
        return this.data.customTitle
      }
      return this.data.serviceType 
        ? `${this.data.serviceType} for ${this.request.vrm}`
        : `Check for ${this.request.vrm}`
    }
  },
  async mounted() {
    if (this.data) {
      this.request.vrm = this.data.vrm
      this.request.vin = this.data.vin
      this.request.mileage = this.data.mileage?.value || null
      this.request.mileageEstimated = this.data.mileage?.estimate || false
      
    }

    if (this.$experian && this.permissions.dataGuarantee) {
      this.request.dgLevel = 10000
      this.request.dgTerm = 12
    }

    if (!this.hasCazanaValues || !this.request.mileage) {
      this.request.valuationCazana = false
    }
  },
  methods: {
    track(action) {
      this.$mxp.track(`${this.data.serviceType}_modal_${action}`)
    },
    toggleUpsell() {
      this.track('cazana_valuation_upsell')
      this.$modal.open('companion/UpsellCompanion')
    },
    updateMileage(value) {
      this.request.mileage = value
      this.request.mileageEstimated = false
      this.track('update_mileage')
    },
    close() {
      this.track('cancel_check')
      this.$modal.close()
    },
    trackRequestedValuations() {
      this.track(`request_${this.data.serviceType}`)
      if (this.request.valuationCazana) this.track(`request_cazana`)
      if (this.request.valuationCap) this.track(`request_cap`)
      if (this.request.valuationGlass) this.track(`request_glass`)
    },
    async submit() {
      try {
        this.$wait.start('loading')
        this.trackRequestedValuations()
        const params = { request: this.request, email: this.user.email }

        if (this.data.serviceType === 'Motorcheck') {
          try {
            this.$wait.start('loading')
            
            const { url } = await motorcheck.getReport({ 
              vrm: this.request.vrm, 
              mileage: this.request.mileage 
            })

            this.track(`successful_${this.data.serviceType}_request`)
            
            if (!url || typeof url !== 'string') {
              throw new Error('Invalid URL received from report')
            }

            window.location.href = url
            this.$modal.close()

          } catch (error) {
            this.track(`failed_${this.data.serviceType}_request`)
            this.$notify('Unable to open the report. Please try again.')
          } finally {
            this.$wait.end('loading')
          }
        } else if (this.data.serviceType === 'AutoCheck') {
          const { uuid } = await autocheck.create(params)
          this.track(`successful_${this.data.serviceType}_request`)
          await this.$router.push({ name: 'autocheck', params: { uuid } })
          this.$modal.close()
        } else {
          throw new Error('Invalid service type')
        }
      } catch (error) {
        this.track(`failed_${this.data.serviceType}_request`)
        this.$notify('We are unable to carry out a check on this vehicle')
        this.$modal.close()
      } finally {
        this.$wait.end('loading')
      }
    },
    fail() {
      this.close()
      this.$notify('We are unable to carry out a check on this vehicle')
    }
  }
}
</script>
